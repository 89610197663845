
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { Dashboard } from 'shared/ui/Layout/Dashboard';
import WestIcon from '@mui/icons-material/West';
import theme from 'shared/theme';
const Error = () => {
    const handleBackClick = () => {
        window.history.back();
    };
    return (<Dashboard>
      <Grid container sx={{
            p: '25px 0 0 25px',
            '@media (max-width: 960px)': {
                p: '5px 0 0 5px',
            },
        }}>
        <Button onClick={handleBackClick} size="small" sx={{ color: theme.palette.secondary.main, mr: 2 }}>
          <WestIcon sx={{
            fontSize: 22,
            [theme.breakpoints.down('sm')]: { fontSize: 15 },
        }}/>
        </Button>
        <Typography variant="h5">
          Данной страницы не существует, пожалуйста, вернитесь назад
        </Typography>
      </Grid>
    </Dashboard>);
};
export default Error;

    async function __Next_Translate__getStaticProps__1940c62a5c3__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1940c62a5c3__ as getStaticProps }
  